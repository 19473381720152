import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/mnt/fstack_dev/nykenshinkai.com/nyksk/src/components/default-mdx-layout.js";
import SEO from "../components/seo";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import BoguzenImage from "../components/boguzen-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Class Information" mdxType="SEO" />
    <p>{`NY Kenshinkai is affiliated with the national All US Kendo Federation and the regional All Eastern US Kendo Federation.`}</p>
    <h1>{`Class Schedule & Events`}</h1>
    <h2><AnchorLink to="#main-location" title="Main Location" mdxType="AnchorLink">{`Main Location - Manhattan, New York`}</AnchorLink></h2>
    <h2><AnchorLink to="#nj-kids" title="NJ Kids Kendo Class" mdxType="AnchorLink">{`NJ Kids Kendo Class - Palisades Park, New Jersey`}</AnchorLink></h2>
    <p>{`Please check our `}<a parentName="p" {...{
        "href": "https://www.facebook.com/NYKenshinkai/",
        "title": "NY Kenshinkai"
      }}>{`Facebook page`}</a>{` or the calendar below for any schedule changes.`}</p>
    <iframe src="https://calendar.google.com/calendar/embed?showTitle=0&amp;showPrint=0&amp;showCalendars=0&amp;mode=AGENDA&amp;height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=radunguic9olhe764etbd3m77o%40group.calendar.google.com&amp;color=%2329527A&amp;ctz=America%2FNew_York" style={{
      "borderWidth": "0"
    }} width="100%" height="250" frameBorder="0" scrolling="no"></iframe>
    <div id="main-location" />{" "}
    <h1>{`Main Location - Manhattan, New York`}</h1>
    <p>{`For adults and children.`}</p>
    <h2>{`Pearl Studios (3rd or 4th Floor)`}</h2>
    <ul>
      <li parentName="ul">{`500 Eighth Avenue, New York, NY 10018 (between 35th & 36th St)`}</li>
      <li parentName="ul">{`To enter Pearl Studios, please show your photo ID at the lobby of the building.`}</li>
      <li parentName="ul">{`Class is usually either on the 3rd or 4th floor.`}</li>
      <li parentName="ul">{`Please check the Pearl Studios schedule screens for the most up-to-date room reservation.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://pearlstudiosnyc.com/index.php"
        }}>{`http://pearlstudiosnyc.com/index.php`}</a></li>
    </ul>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.4112954699463!2d-73.99472198428673!3d40.75297774304387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259adb760bb1d%3A0xc8fe55009a34ae21!2sNY+Kenshinkai!5e0!3m2!1sen!2sus!4v1564978721313!5m2!1sen!2sus" width="100%" height="450" frameBorder="0" style={{
      "border": "0"
    }} allowFullScreen></iframe>{" "}
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Day`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Time`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><b>{`Tuesday`}</b></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`8:00PM-9:30PM`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><b>{`Advanced`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><b>{`Friday`}</b></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`7:30PM-8:15PM`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><b>{`Beginners / Basics`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`8:30PM-9:30PM`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><b>{`Advanced`}</b></td>
        </tr>
      </tbody>
    </table>
    <p>{`New beginners require an appointment.`}</p>
    <h2>{`Payment Information`}</h2>
    <p>{`See one-time and recurring fees for Adults and Youth (up to 17 years old) below.`}<br parentName="p"></br>{`
`}{`Please note we only accept cash or personal checks at this time. `}</p>
    <h3>{`Adults`}</h3>
    <p><em parentName="p">{`One-time`}</em>{` fees to start kendo at NY Kenshinkai:  `}</p>
    <blockquote>
      <p parentName="blockquote">{`First class: free`}<br parentName="p"></br>{`
`}{`Beginner 3-class pack: $50`}</p>
    </blockquote>
    <p><em parentName="p">{`One-time`}</em>{` fee to become a NY Kenshinkai Member:  `}</p>
    <blockquote>
      <p parentName="blockquote">{`New Member fee: $80`}</p>
    </blockquote>
    <p><em parentName="p">{`Recurring`}</em>{` fees for Members:  `}</p>
    <blockquote>
      <p parentName="blockquote">{`Per-class fee: $20 or`}<br parentName="p"></br>{`
`}{`10-class ticket fee: $150`}<br parentName="p"></br>{`
`}{`Kendo Federation Membership fee: $80/year`}</p>
    </blockquote>
    <h3>{`Youth (up to 17 years old)`}</h3>
    <p><em parentName="p">{`One-time`}</em>{` fee to start kendo at NY Kenshinkai:  `}</p>
    <blockquote>
      <p parentName="blockquote">{`First class: free`}</p>
    </blockquote>
    <p><em parentName="p">{`One-time`}</em>{` fee to become a NY Kenshinkai Youth Member:  `}</p>
    <blockquote>
      <p parentName="blockquote">{`New Youth Member fee: $50`}</p>
    </blockquote>
    <p><em parentName="p">{`Recurring`}</em>{` fees for Youth Members:  `}</p>
    <blockquote>
      <p parentName="blockquote">{`Per-class fee: $15 or`}<br parentName="p"></br>{`
`}{`10-class ticket fee: $130`}<br parentName="p"></br>{`
`}{`Kendo Federation Youth Membership fee: $50/year`}</p>
    </blockquote>
    <h3>{`Visitors (non-NY Kenshinkai members)`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Per class fee: $20`}</p>
    </blockquote>
    <h2>{`Kendo Equipment`}</h2>
    <p>{`To ensure safety, all practitioners must use approved kendo equipment. Our primary equipment supplier is `}<OutboundLink href="https://www.boguzen.com" title="Boguzen.com" mdxType="OutboundLink">{`Boguzen.com`}</OutboundLink>{`.`}<br parentName="p"></br>{`
`}{`Boguzen is owned by `}<Link to="/instructors/daniel-lee/" mdxType="Link">{`Daniel Lee`}</Link>{` sensei. They are one of the main kendo equipment suppliers in the US Northeast region.`}</p>
    <BoguzenImage mdxType="BoguzenImage" />
    <div id="nj-kids" />{" "}
    <h1>{`NJ Kids Kendo Class - Palisades Park, New Jersey`}</h1>
    <p>{`Primarily for children. Parents are encouraged to join practice. Adult kendo practitioners are also welcome!`}<br parentName="p"></br>{`
`}{`Regular attendance usually consists of around 10 children.`}<br parentName="p"></br>{`
`}{`Kendo is a martial art. There is a risk of injuries associated with participation in NY Kenshinkai activities.`}</p>
    <h2>{`in care of Garden State Kendo, 534 Bergen Blvd, Palisades, Park NJ 07650`}</h2>
    <ul>
      <li parentName="ul">{`Members are also welcome to join our Manhattan practices every Tuesday and Friday.`}</li>
    </ul>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.074749048896!2d-73.98677818428453!3d40.84828263720329!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f71dc464ed81%3A0xc2b869e3347646ea!2sGarden+State+Kendo!5e0!3m2!1sen!2sus!4v1564978642884!5m2!1sen!2sus" width="100%" height="450" frameBorder="0" style={{
      "border": "0"
    }} allowFullScreen></iframe>{" "}
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Day`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Time`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}><b>{`Saturday`}</b></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`3:30PM-4:15PM`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><b>{`Beginners`}</b></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`4:15-5:30PM`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><b>{`Bogu (Armor)`}</b></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Payment Info`}</h2>
    <ul>
      <li parentName="ul">{`$15 per child per class`}</li>
    </ul>
    <h2>{`Kendo Equipment`}</h2>
    <p>{`To ensure safety, all practitioners must use approved kendo equipment. Our primary equipment supplier is `}<OutboundLink href="https://www.boguzen.com" title="Boguzen.com" mdxType="OutboundLink">{`Boguzen.com`}</OutboundLink>{`.`}<br parentName="p"></br>{`
`}{`Boguzen is owned by `}<Link to="/instructors/daniel-lee/" mdxType="Link">{`Daniel Lee`}</Link>{` sensei. They are one of the main kendo equipment suppliers in the US Northeast region.`}</p>
    <BoguzenImage mdxType="BoguzenImage" />

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
  }
  
  .grvsc-code {
    display: inline-block;
    min-width: 100%;
  }
  
  .grvsc-line {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-line-highlighted {
    background-color: var(--grvsc-line-highlighted-background-color, transparent);
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, transparent);
  }
  
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      